import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem('user')
      // console.log('Routes!!!!')
      if (!loggedIn) {
        // 若不成立則回到login登入頁面
        next()
      } else {
        // 有驗證就進到該頁面
        next('/platform')
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem('user')
      // console.log('Routes!!!!')
      if (!loggedIn) {
        // 若不成立則回到login登入頁面
        next()
      } else {
        // 有驗證就進到該頁面
        next('/platform')
      }
    }
  },
  {
    path: '/platform',
    name: 'Platform',
    component: () => import('../views/Platform')
  },
  {
    path: '/node',
    name: 'Node',
    component: () => import('../views/Node')
  }
  // {
  //   path: '/action',
  //   name: 'Action',
  //   component: () => import('../views/Action')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/']
  // 取得是否為需要Auth的routes的bool，不是public則為需要Auth: true、反之false
  const authRequired = !publicPages.includes(to.path)
  // 看當前是否有localstorage存放(token)
  // 有兩種情況會沒有token，過期或者logout登出
  const loggedIn = localStorage.getItem('user')
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    // 若不成立則回到login登入頁面
    next('/login')
  } else {
    // 有驗證就進到該頁面
    next()
  }
})

export default router
